/* @font-face {
    font-family: 'Gotham';
    src: url(../fonts/gotham/Gotham-Light.otf);
} */

*{
margin: 0px;
padding: 0px;
}

.container-hsb{
    padding-left: 88px;
    padding-right: 88px;
    padding-top: 11px;
    padding-bottom: 11px;
}

:root{
    --primary-color: #00524b;
    --secondary-color: #ae2e35;
    --primary-font: 'Montserrat', sans-serif;
    --secondary-font: 'Montserrat', sans-serif;
    --secondary2-font: 'Lato', sans-serif;
    /* --secondary2-font: 'Gotham', sans-serif; */
    }

/* Text Color */
.text-pri-hsb{
color: var(--primary-color) !important;
}

.text-sec-hsb{
    color: var(--secondary-color);
}

/* Background Color */

.bg-pri{
    background-color: var(--primary-color) ;
    }
    
.bg-sec{
    background-color: var(--secondary-color);
    }

/* Font Family*/

.font-pri-hsb{
    font-family: var(--primary-font);
}

.font-sec-hsb{
    font-family: var(--secondary-font);
}

.font-sec2-hsb{
    font-family: var(--secondary2-font);
}

.font-14{
    font-size: 14px;
}
.font-16{
    font-size: 16px;
}
.font-17{
    font-size: 16px;
}

.font-18{
    font-size: 18px;
}

.font-16-banner{
    font-size: 16px;
}

.font-20-banner-home{
    font-size: 25px;
}

.font-30-banner{
    font-size: 30px;
}

.font-30-banner-home{
    font-size: 30px;
}

.font-46-banner{
    font-size: 46px;
}

.font-46-banner-home{
    font-size: 46px;
}
.font-20{
    font-size: 20px;
}

.font-25{
    font-size: 20px;
}

.font-25-btn{
    font-size: 25px;
}

.font-30{
    font-size: 30px;
}

.fs-ss-20{
    font-size: 20px;
}
.fs-ss-14{
    font-size: 14px;
}

.fs-ss-16{
    font-size: 16px;
}

.font-35{
    font-size: 30px;
}

.font-35-btn{
    font-size: 35px;
}

.font-46{
    font-size: 46px;
}

.fw-400{
    font-weight: 400;
}

.fw-500{
    font-weight: 500;
}

.fw-600{
    font-weight: 600;
}

.fw-700{
    font-weight: 700;
}

.fw-800{
    font-weight: 800;
}

.lh-30{
    line-height: 40px;
}

.lh{
    line-height: 50px;
}

.nav-link{
    color: rgb(255, 255, 255) !important;
    font-size: 16px !important;
}

.nav-link:hover{
    color: rgb(230, 230, 230);
}

/* .navbar-nav{
    padding-left: 60px; 
} */

.td-none{
    text-decoration: none;
}

.td-none:hover{
    color: var(--primary-color);
}

.bs-btn{
border-left: 2px solid white;
padding-left: 21px;
}

.bg-footer{
    background-image: url(../images//home/Footerbg.png);
    background-repeat: no-repeat;
    background-position: top right ;
    background-size: cover;
}

.padding-footer{
    padding-top: 176px;
    padding-bottom: 30px;
}

.home-bg{
    background-repeat: no-repeat;
    background-position: bottom right;
    background-size: cover;
    width: 100%;
    height: max-content;
}

.home-padding{
    padding-top: 200px;
    padding-bottom: 280px;
}

.home-logo-width{
    width: 200px;
}

.hr{
    border: 2px solid #00524b;
    opacity: 1.0;
    color:  #eeebeb ;
}

.Banner2{
    background-repeat: no-repeat;
    background-position: right;
    background-size: cover;
    height: max-content;
}
.table{
    --bs-table-bg: #00000000 !important;
    --bs-table-color: #ffffff !important;
}

.section-img-1{
    background-position: unset;
    background-size: contain ;
    background-repeat: no-repeat;
    background-attachment: fixed;

}

.section-img-2{
    background-position: right;
    background-size: contain;
    background-repeat: no-repeat;
    background-attachment: fixed;

}

.hr2{
    border: 2px solid #00524b;
    opacity: 1.0;
    margin-left: auto;
    margin-right: auto;
    width: 12%;
}

.hr3{
    border: 2px solid #ffffff;
    background-color: #ffffff;
    opacity: 1.0;
    width: 12%;
    margin-left: 17px;
}



.insurance-logo img{
    width: 180px;
}

.bg-sample{
    position: relative;
}

.banner-text{
    position: absolute;
    top: 35%;
    left: 0px;
}

.banner-text-home{
    position: absolute;
    top: 27%;
    left: 0px;
}


.border-pri-end{
    border-right: 2px solid #00524b;
}

.border-pri-start{
    border-left: 2px solid #00524b;
}

.dropdown-item:hover{
    background-color: #00524b !important;
    color: #ffffff !important;
}

.forms-links a{
    text-decoration: none;
    color: var(--primary-color);
    font-size: 17px;
    line-height: 36px;
    font-family: var(--primary-color);
    font-weight: 500;
}

.nav-item{
    padding-right: 12px;
}

.dropdown-menu{
    display: none;
}

.right-menu:hover .dropdown-menu{
    display: block;
}


@media screen and (min-width: 1048px) and (max-width: 1218px) {
    .font-16-upper-header{
        font-size: 13px !important;
    } 
    .nav-link{
        font-size: 13px !important;
    }
    
    .bg-footer{
        background-image: none;
        background: #1d1f27;
        background-repeat: no-repeat;
        background-position: top right ;
        background-size: cover;
    }
    .padding-footer{
        padding-top: 47px;
        padding-bottom: 0px;
    }
}
@media screen and (min-width: 1200px) {
    .section-img-1-providers{
        background-position-x: -200px !important;
    }
    
    .section-img-2-providers{
        background-position-x: 580px !important;
    }
}

@media screen and (min-width: 1200px) and (max-width: 1366px) { 

    .font-nav{
        font-size: 14px;
    }
    
}
@media screen and (min-width: 992px) and (max-width: 1048px) { 
    .section-img-2{
        background-position: 100% !important;
    }

    
    .font-20-banner-home{
        font-size: 26px;
        line-height: 18px;

    }

    .font-nav{
        font-size: 12px;
    }
    .banner-text-home{
        position: absolute;
        top: 18%;
        left: 0%;
}

.bg-footer{
    background-image: none;
    background: #1d1f27;
    background-repeat: no-repeat;
    background-position: top right ;
    background-size: cover;
}
.padding-footer{
    padding-top: 0px;
    padding-bottom: 0px;
}
.banner-text{
    position: absolute;
    top: 29%;
    left: 0px;
}
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
    .section-img-1{
        background-size: cover;
        background-attachment: local;
        background-position: center;
        
    }
    
    .section-img-2{
        background-size: cover;
        background-attachment:local;
        background-position: center;
    }
}

@media screen and (min-width: 576px) and (max-width: 991px) {
    .section-img-1{
        background-size: cover;
        background-attachment: local;
        background-position: center;
        
    }
    
    .section-img-2{
        background-size: cover;
        background-attachment:local;
        background-position: center;
    }
    .nav-link{
        color: white !important;
        font-size: 16px !important;
    }
    .font-40-landing-page{
        font-size: 25px !important;
    }
    .font-30-landing-page{
        font-size: 25px !important;
    }
    
    .font-18-landing-page{
        font-size: 17px !important;
    }

    .font-16-landing-page-head{
        font-size: 12px !important;
    }

    .font-16-landing-page{
        font-size: 15px !important;
    }

    .font-20-banner-home{
        font-size: 20px;
        line-height: 18px;

    }

    .font-46-banner-home{
        font-size: 28px;
        line-height: 18px;

    }

    .font-35{
        font-size: 25px;
    }
    .font-25{
        font-size: 20px;
    }
    .font-17{
        font-size: 14px;
    }
    .navbar-nav{
        padding-left: 0px; 
    }   

    .bs-btn{
        border: none;
        margin-bottom: 12px;
    }
    .banner-text-home{
            position: absolute;
            top: 18%;
            left: 0px;
    }
    .font-30-banner-home{
        padding-top: 8px;
        font-size: 21px;
        line-height: 12px;
    }
    .font-46-banner-home{
        font-size: 28px;
        line-height: 37px;
    }
    .font-16-banner{
        font-size: 12px;
    }
    
    .bg-footer{
        background-image: none;
        background: #1d1f27;
        background-repeat: no-repeat;
        background-position: top right ;
        background-size: cover;
    }
    .padding-footer{
        padding-top: 0px;
        padding-bottom: 0px;
    }
    
.banner-text{
    position: absolute;
    top: 20%;
    left: 0px;
}
}

@media (max-width: 575px) {
    .w-logo-landing-page{
        width: 180px !important ;
    }
    .font-35-below-banner{
        font-size: 22px !important;
    }
    .font-30-below-banner{
        font-size: 20px !important;
    }
    .fs-ss-30-btn{
        font-size: 18px !important ;
    }
    .fs-ss-20-btn{
        font-size: 18px !important ;
    }
    
    .fs-ss-46{
        font-size: 24px !important;
    }

    .fs-ss-35{
        font-size: 25px !important;
    }
    .fs-ss-35-banner{
        font-size: 18px !important;
    }
    .fs-ss-30{
        font-size: 20px !important;
    }
    .fs-ss-22{
        font-size: 18px !important;
    }

    .font-16-upper-header{
        font-size: 12px !important;
    }
    .nav-link{
        font-size: 16px !important;
    }
    .font-40-landing-page{
        font-size: 30px !important;
    }
    
    .font-18-landing-page{
        font-size: 16px !important;
    }

    .font-30-landing-page{
        font-size: 22px !important;
    }
    
    .font-35-landing-page{
        font-size: 25px !important;
    }

    .font-16-landing-page-head{
        font-size: 12px !important;
    }

    .font-16-landing-page{
        font-size: 15px !important;
    }

    .section-img-1{
        background-size: cover;
        background-attachment: local;
    }
    
    .section-img-2{
        background-size: cover;
        background-attachment:local;
    }
    
    .font-20-banner-home{
        font-size: 14px;
    }

    .container-hsb{
        padding-left: 22px;
        padding-right: 22px;
    }
    .font-16{
        font-size: 12px;
    }
    .nav-item{
        padding-left: 0px;
        padding-right: 0px;
    }
    .bs-btn{
        border-left: none;
    }
    .font-46{
            font-size: 21px;
    }
    .home-logo-width{
            width: 170px;
    }
    .font-25{
            font-size: 20px;
    }
    
    .font-30{
        font-size: 20px;
}
    .font-35{
        font-size: 25px;
    }
    .font-25-btn{
        font-size: 18px;
    }
    .font-35-btn{
        font-size: 25px;
    }
    .lh{
        line-height: 40px;
    }

        .navbar-nav{
            padding-left: 0px; 
        }

        .home-padding{
            padding-top: 40px;
            padding-bottom: 40px;
        }

        .home-bg{
            background-position: bottom left;
        }

        
        .font-46-banner{
            font-size: 25px;
        }

        
        .font-46-banner-home{
            font-size: 21px;
            line-height: 26px;

        }

        .font-30-banner{
            font-size: 16px;
            line-height: 22px;
        }

        .font-30-banner-home{
            padding-top: 8px;
            font-size: 16px;
            line-height: 2px;
        }

        .insurance-logo img{
            width: 120px;
        }

        .banner-text{
            top: 21%;
            left: 0px;
        }

        .banner-text-home{
            position: absolute;
            top: 26%;
            left: 0px;
        }

        .font-16-banner{
            font-size: 9px;
        }

        .border-pri-end{
            border: none;
        }
        
        .border-pri-start{
            border: none;
        }

        .bg-footer{
            background-image: none;
            background: #1d1f27;
            background-repeat: no-repeat;
            background-position: top right ;
            background-size: cover;
        }

        .padding-footer{
            padding-top: 0px;
            padding-bottom: 0px;
        }
    }



    .landing-page-banner{
        background-image: url(../images/landingpage/header.webp);
        background-position: top;
        background-repeat: no-repeat;
        background-size: cover;
        height: min-content;
    }

    .landing-page-logo{
        width: 290px;
    }

    .landing-page-border-below{
        border-bottom: 2px solid #00524b !important;
    }

    .landing-page-footer{
        background-image: url(../images/landingpage/footer.webp);
        background-position: top;
        background-repeat: no-repeat;
        background-size: cover;
        height: min-content;
    }

    .pattern-doctors{
    background: linear-gradient(rgb(252, 252, 252)50%, rgb(233, 233, 233) 50%) !important;
    border: 4px solid #8d8d8d;
    border-top-left-radius: 40px;
    border-top-right-radius: 40px;
    box-shadow: -2px 3px 3px 1px rgba(90, 90, 90, 0.15);
    }

    .font-16-landing-page-head{
        font-size: 16px;
    }

    .font-16-landing-page{
        font-size: 16px;
    }

    .font-35-landing-page{
        font-size: 35px;
    }
    .font-35-below-banner{
        font-size: 35px;
    }
    .font-30-below-banner{
        font-size: 30px;
    }

    .font-30-landing-page{
        font-size: 30px;
    }
    .fs-ss-30-btn{
        font-size: 30px ;
    }
    .fs-ss-20-btn{
        font-size: 20px !important ;
    }

    .font-21-landing-page{
        font-size: 21px
    }

    .font-20-landing-page{
        font-size: 18px ;
    }

    .font-18-landing-page{
        font-size: 18px;
    }

    .col-landing-page{
        width: 23% !important;
    }

    .shadow-landing-page{
        box-shadow: -2px 3px 3px 1px rgba(90, 90, 90, 0.15);
    }

    .bg-services-landing-page{
        background-image: url(../images/landingpage/servicesbg.webp);
        background-position: bottom;
        background-repeat: no-repeat;
        background-size: contain;
    }

    .middle-bg-landing-page{
        background-image: url(../images/landingpage/middlebanner.webp);
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;   
    }

    .font-40-landing-page{
        font-size: 40px;
    }

    .border-below{
        width: 220px !important;
    }

    .border-below-center{
        width: 130px !important;
    }

    .bg-grey{
        background-color: #e9e9e9;
    }

    .w-logo-landing-page{
        width: 250px;
    }

    .font-16-upper-header{
        font-size: 16px;
    }

    .text-black{
        color: black !important;
    }

    .fs-ss-46{
        font-size: 46px;
    }

    .fs-ss-35{
        font-size: 35px;
    }
    .fs-ss-35-banner{
        font-size: 35px;
    }

    .fs-ss-30{
        font-size: 30px;
    }

    .fs-ss-22{
        font-size: 22px;
    }

    .hover-pri{
        background-color: var(--secondary-color);
    }

    .hover-pri:hover{
        background-color: #c4383f !important;
    }

    .bg-btn-primary{
        background-color: #ae2e35 !important;
        color: white;
        border: none !important;
    }

    .position-sticky-landing-page{
        position: sticky !important;
        top: 0 !important;
    }

    .col-star{
        color: #f9bd0c !important;
    }
    
    .slick-prev:before, .slick-next:before {
        color: #000000 !important;
        opacity: 1;
    }

    .shadow-review{
        box-shadow: 0 0 2px rgba(0,0,0,.12),0 2px 4px rgba(0,0,0,.24)!important;
    }

    